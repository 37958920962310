
import { defineComponent, ref, reactive, onMounted } from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import { OrdersFilter } from '@/models/Order';
import { generalStore } from '@/store';
import router from '@/router';
import { OrderStatus, ArrivalConfirmation, ToOptions } from '@/models/Enums';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import { api } from '@/services/Api';
import { DateRange, IntIdName } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import { Product } from '@/models/Product';
import { useI18n } from 'vue-i18n';
import CheckBoxInput from '../CheckBoxInput.vue';

export default defineComponent({
    components: { Multiselect, Calendar, CheckBoxInput },
    name: 'OrderFilter',
    data() {
        return {};
    },
    async setup() {
        const { t } = useI18n();
        const calendar = ref();
        const { convertDate } = useOrder();
        const { dateRangeValidate } = useProduct();
        const filter = ref<OrdersFilter>();
        const priceTypes = ref();
        const channels = ref();
        const loaded = ref(false);
        const arrivalTypes = ref<IntIdName[]>([]);
        const products = ref<Product[]>([]);
        const reservationDateRange = reactive(new DateRange());
        const arrivalDateRange = reactive(new DateRange());

        if (filter.value?.startDate) {
            reservationDateRange.fromDate = new Date(filter.value?.startDate);
        }
        if (filter.value?.endDate) {
            reservationDateRange.toDate = new Date(filter.value?.endDate);
        }
        if (filter.value?.arrivalStartDate) {
            arrivalDateRange.fromDate = new Date(filter.value?.arrivalStartDate);
        }
        if (filter.value?.arrivalEndDate) {
            arrivalDateRange.toDate = new Date(filter.value?.arrivalEndDate);
        }
        const orderStatus = ref<IntIdName[]>([]);

        //functions
        function ResetFilter() {
            router.push({ name: 'orders', params: { newSearch: '1' } });
        }

        function Close() {
            router.push({ name: 'orders' });
        }

        function Search() {
            if (filter.value) {
                if (reservationDateRange.fromDate) {
                    filter.value.startDate = convertDate(reservationDateRange.fromDate);
                }
                if (reservationDateRange.toDate) {
                    filter.value.endDate = convertDate(reservationDateRange.toDate);
                }
                if (arrivalDateRange.fromDate) {
                    filter.value.arrivalStartDate = convertDate(arrivalDateRange.fromDate);
                }
                if (arrivalDateRange.toDate) {
                    filter.value.arrivalEndDate = convertDate(arrivalDateRange.toDate);
                }
            }

            generalStore.commit('saveOrdersFilter', filter.value);
            router.push({ name: 'orders', params: { newSearch: '1', keepFilter: '1' } });
        }

        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };
        function capitalizeFirstLetter(text: string) {
            return text.charAt(0).toLowerCase() + text.slice(1);
        }

        async function onMountedAction() {
            const sloterFilter = generalStore.getters.getOrderFilter;
            filter.value = JSON.parse(JSON.stringify(sloterFilter));
            priceTypes.value = await generalStore.dispatch('getPriceTypes');
            channels.value = await generalStore.dispatch('getChannels');
            const apiResult = await api.getProducts({ productName: '', typeId: '', status: null, keyword: '' });
            products.value = apiResult.data?.products.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1)) || [];
            for (const [propertyKey, propertyValue] of Object.entries(OrderStatus)) {
                if (!Number.isNaN(Number(propertyKey))) continue;
                orderStatus.value.push({ id: Number(propertyValue), name: propertyKey });
            }
            for (const [propertyKey, propertyValue] of Object.entries(ArrivalConfirmation)) {
                if (!Number.isNaN(Number(propertyKey))) continue;
                if (propertyValue == 0) continue;
                arrivalTypes.value.push({
                    id: Number(propertyValue),
                    name: t(`order.arrivalConfirmation.${capitalizeFirstLetter(propertyKey)}`)
                });
            }
            await Q.delay(400);
            swal.close();
            loaded.value = true;
        }

        onMounted(onMountedAction);

        return {
            filter,
            ResetFilter,
            Close,
            Search,
            priceTypes,
            products,
            orderStatus,
            reservationDateRange,
            calendar,
            dateChanged,
            arrivalDateRange,
            channels,
            arrivalTypes,
            loaded
        };
    }
});
